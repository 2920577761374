import type { FC } from 'react'
import React from 'react'
import type { TOrderType } from '@/types/HarvestSaleLots'
import { messages } from './messages'
import './styles.scss'

const CustomLabel: FC<{ type: TOrderType }> = ({ type }) => (
  <div>
    <h5>{messages.header[type]}</h5>
    <span className="text_small">{messages.description[type]}</span>
  </div>
)

export default CustomLabel
