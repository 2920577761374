import type { TMessages } from '@/types/TMessages'

export const messages = {
  requestHarvestSaleModal: {
    buyer: {
      label: 'Покупатель*',
      notBeenFound: 'Не найдено покупателей<br/> по выбранной культуре и базису',
    },
  },
} satisfies TMessages
