import { useEffect, useRef, useState } from 'react'
import { useAuthenticateUser } from '@/components/ui/AuthButton/hooks'

export type TDefaultMakeAction = (...args: any[]) => void

export interface TUseActionWithAuthorizationApi<GMakeAction> {
  makeAuthorizedAction: GMakeAction | TDefaultMakeAction
  isActionPermitted: boolean
  isAuthenticated: boolean
}

export const useActionWithAuthorization = <GMakeAction extends TDefaultMakeAction>(
  makeAction: GMakeAction,
  withExtraCondition: boolean = true,
): TUseActionWithAuthorizationApi<GMakeAction> => {
  const { isAuthenticated, openAuthModal } = useAuthenticateUser()
  const savedCallback = useRef<() => void>()

  const [shouldOpenDialogOnAuth, setMakingActionOnAuthState] = useState<boolean>()

  useEffect(() => {
    if (shouldOpenDialogOnAuth && isAuthenticated && withExtraCondition) {
      savedCallback.current?.()
      setMakingActionOnAuthState(false)
      savedCallback.current = undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldOpenDialogOnAuth, isAuthenticated, withExtraCondition])

  return {
    makeAuthorizedAction: (...args) => {
      if (isAuthenticated) makeAction(...args)
      else {
        savedCallback.current = () => makeAction(...args)

        openAuthModal({ onCloseDialog: () => setMakingActionOnAuthState(true) })
      }
    },
    isActionPermitted: isAuthenticated && withExtraCondition,
    isAuthenticated,
  }
}
