import type { FC } from 'react'
import React, { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import difference from 'lodash/difference'
import { Filters } from '@/components/resolvers'
import { DropdownWithTags } from '@/components/ui/fields'
import { getLocationQuery } from '@/logic/app/reducer'
import { TemplatesFilterName } from '@/logic/harvestSale/constants'
import { getFilterOptions, getFilters } from '@/logic/harvestSale/reducer'
import { pushGtmEventOnChangeFilterFormClick } from '@/logic/metrika/harvest/helpers'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import { HarvestSaleLotsContext } from '@/pages/HarvestSaleLots/constants'
import type { RootState } from '@/redux/interfaces'
import type { ILotsFilter } from '@/types/HarvestSaleLots'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { EBuyerIdsWithFCADescriptionInsideElevators } from './constants'
import type { ILeftFilterProps } from './interfaces'
import { messages } from './messages'
import './styles.scss'

const mapState = (state: RootState) => ({
  asideFilters: getFilters()(state).aside,
  regionOptions: getFilterOptions()(state)[TemplatesFilterName.REGIONS] || [],
  search: getLocationQuery(state),
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

const LeftFilter: FC<ILeftFilterProps> = ({ regionTagsPortal }) => {
  const { onChangeFilter, dropdownKey, formValues = {}, saveFilterValues } = useContext(HarvestSaleLotsContext)

  const { asideFilters, regionOptions, userGtmInfo, userRegion } = useSelector(mapState)

  const { isDesktop, isMobile } = useDeviceType()

  const shouldShowFcaDescriptionForBuyerId = useMemo(() => {
    const buyerIds = formValues?.buyers
    if (!buyerIds) return false

    const selectedBuyerIds = Object.keys(buyerIds).filter(id => buyerIds[id])
    if (!selectedBuyerIds.length) return false

    return !difference(selectedBuyerIds, Object.values(EBuyerIdsWithFCADescriptionInsideElevators)).length
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(formValues)])

  const { regionCodes = [] } = formValues

  const [buyerCheckboxes, ...otherCheckboxes] = useMemo(
    () =>
      asideFilters.reduce((result: ILotsFilter[], filter) => {
        let filterResult: ILotsFilter = filter
        if (filter.category === 'elevators') {
          let zeroOptionsMessage
          if (shouldShowFcaDescriptionForBuyerId) {
            zeroOptionsMessage = messages.elevatorOptions.fcaDescription
          } else if (regionCodes.length) {
            zeroOptionsMessage = messages.elevatorOptions.zero
          } else {
            zeroOptionsMessage = messages.elevatorOptions.noSelectedRegions
          }

          filterResult = {
            ...filter,
            options: filter.options.filter(({ dependencies }) => dependencies?.some(({ value }) => regionCodes.includes(value))),
            zeroOptionsMessage,
          }
        } else if (filter.category === 'buyers') {
          filterResult = {
            ...filter,
            zeroOptionsMessage: messages.elevatorOptions.zero,
          }

          return [filterResult, ...result]
        }

        return [...result, filterResult]
      }, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [regionCodes, JSON.stringify(asideFilters), shouldShowFcaDescriptionForBuyerId],
  )

  const onCloseTag = () => {
    const { regionCodes: selectedCodes = [] } = formValues

    if (selectedCodes.length === 1) {
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newFormValues: Record<string, any> = { ...formValues, regionCodes: [] }
      delete newFormValues?.[TemplatesFilterName.ELEVATORS]
      delete newFormValues?.[TemplatesFilterName.BUYERS]
      saveFilterValues(newFormValues)
    }
  }

  const TitleTag = isMobile ? 'h5' : 'h3'

  return (
    <div className="harvest-sale-left-filter">
      {buyerCheckboxes && (
        <div className="col-10-only-md">
          <Filters
            filters={[buyerCheckboxes]}
            onChange={onChangeFilter}
            controlStyle={isDesktop ? EColor.WHITE : EColor.LIGHT_GRAY}
            userGtmInfo={userGtmInfo}
            userRegion={userRegion}
          />
        </div>
      )}
      {regionOptions.length > 0 && (
        <div className="harvest-sale-left-filter__rest-filters col-8-only-md">
          <TitleTag className="space-holder24-from-sm space-holder16-sm">{messages.regions.title}</TitleTag>
          <DropdownWithTags
            className="harvest-sale-left-filter__select-item"
            name="regionCodes"
            onChange={e => {
              onChangeFilter()
              pushGtmEventOnChangeFilterFormClick(userGtmInfo, userRegion.name, 'regionCodes', e[e.length - 1])
            }}
            options={regionOptions}
            preselectedValues={formValues.regionCodes}
            modifiers={isDesktop ? ['white'] : ['light-grey']}
            placeholder={messages.regions.placeholder}
            key={dropdownKey}
            tagsPortalTarget={regionTagsPortal}
            shouldDelegateTagsToOutsideComponents
            onCloseTag={onCloseTag}
            isSearchable
          />
        </div>
      )}
      <div className="harvest-sale-left-filter__rest-filters col-10-only-md">
        <Filters
          filters={otherCheckboxes}
          userGtmInfo={userGtmInfo}
          userRegion={userRegion}
          onChange={onChangeFilter}
          controlStyle={isDesktop ? EColor.WHITE : EColor.LIGHT_GRAY}
          eventLabel={'regionCodes'}
        />
      </div>
    </div>
  )
}

export default LeftFilter
