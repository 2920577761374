import type { TMessages } from '@/types/TMessages'

export const messages = {
  volume: {
    title: 'Объём партии',
    label: 'т',
  },
  createRequest: 'Создать заявку',
  culture: 'Культура',
  deliveryBasis: 'Базис поставки',
} satisfies TMessages
