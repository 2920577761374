import React from 'react'
import { Field } from 'react-final-form'
import CheckboxArrayView from './CheckboxArrayView'
import type { ICheckboxArrayGroupProps, TCheckboxArrayViewProps } from './interfaces'

const CheckboxArray: React.FC<ICheckboxArrayGroupProps> = props => (
  <Field<string[]> {...props}>{(fieldProps: TCheckboxArrayViewProps) => <CheckboxArrayView {...fieldProps} {...props} />}</Field>
)

export default CheckboxArray
