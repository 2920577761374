import type { TMessages } from '@/types/TMessages'

export const messages = {
  tabletTitle: 'Фильтры',
  title: 'Фильтр предложений',
  buttons: {
    resetFilter: 'Сбросить фильтр',
    accept: 'Применить',
  },
} satisfies TMessages
