import type { TMessages } from '@/types/TMessages'

export const messages = {
  sendAnOfferButton: {
    text: 'Откликнуться',
    short: 'Заявка',
  },
  info: 'Цена без НДС',
  validTo: 'Действует до {date}',
} satisfies TMessages
