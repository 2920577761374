import { createContext } from 'react'
import { homeBreadcrumb } from '@/components/ui/Breadcrumbs/constants'
import { TemplatesFilterName } from '@/logic/harvestSale/constants'
import { EHarvestSaleRoute } from '@/types'
import type { TTemplatesFilterName } from '@/types/HarvestSaleLots'
import type { IHarvestSaleLotsContext } from './interfaces'

export const templatesPerPage = 6

export const harvestSaleBreadcrumbs = [homeBreadcrumb, { captionId: 'breadcrumbs.harvestSale', link: '/harvest-sale' }]
export const notAgrarianHarvestSaleBreadcrumbs = [homeBreadcrumb, { captionId: 'breadcrumbs.harvestSale', link: EHarvestSaleRoute.lots }]

export const excludeFilters: TTemplatesFilterName[] = [
  TemplatesFilterName.CULTURES,
  TemplatesFilterName.CULTURE_CLASSES,
  TemplatesFilterName.PROTEIN,
  TemplatesFilterName.REGIONS,
  TemplatesFilterName.STORE,
]

export const HarvestSaleLotsContext = createContext<IHarvestSaleLotsContext>({} as IHarvestSaleLotsContext)

export const storePlaceholder = 'placeholder'

export const excludedFilterNames: string[] = [TemplatesFilterName.CULTURES, TemplatesFilterName.STORE, TemplatesFilterName.SORT_BY]

export const harvestSaleVolumeLimits = {
  min: 25,
  max: 1000000,
}
