import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import type { IDropdownOption } from '@frontend/pole-ui/lib/components/Dropdown'
import { useQuery } from '@tanstack/react-query'
import isEmpty from 'lodash/isEmpty'
import { getSaleTemplatesForEachCultures, getSaleTemplatesForEachCulturesQueryKey } from '@/api/kubik/monolith'
import { transformOptions } from '@/components/ui/fields/helpers/Dropdown'
import { TemplatesFilterName } from '@/logic/harvestSale/constants'
import { getFilterOptions } from '@/logic/harvestSale/reducer'
import { getCreatingDraftHarvestRequestBody } from '@/pages/HarvestSaleLots/helpers'
import DraftService from '@/services/DraftService/DraftService'
import type { IHarvestSaleDraft } from '@/services/DraftService/interfaces'
import type { ITemplate } from '@/types/HarvestSaleLots'
import { getAvailableDeliveryBasisOptions, transformFormValuesToRequestPayload } from './helpers'
import type { IRequestHarvestSaleByTemplateFormValues, IUseDraftProps } from './interfaces'

export const useQualityOptionsFromFilter = () => {
  const filterOptions = useSelector(getFilterOptions())

  return {
    cultureClassOptions: filterOptions[TemplatesFilterName.CULTURE_CLASSES],
    cultureOptions: filterOptions[TemplatesFilterName.CULTURES],
    proteinOptions: filterOptions[TemplatesFilterName.PROTEIN],
  } as const
}

export const useDeliveryBasisOptions = (template?: ITemplate) => {
  const { data: deliveryBasisOptionsData } = useQuery({
    queryKey: getSaleTemplatesForEachCulturesQueryKey({ limit: 1 }),
    queryFn: () => getSaleTemplatesForEachCultures({ limit: 1 }),
    staleTime: Infinity,
    select: result => transformOptions({ options: result.data?.deliveryBasisVariants, valueKey: 'id' }) as IDropdownOption<string>[],
  })

  const deliveryBasisOptions = useMemo(() => {
    if (!template) {
      return deliveryBasisOptionsData?.filter(({ value }) => value !== 'port') || []
    }

    return getAvailableDeliveryBasisOptions(template)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(template), deliveryBasisOptionsData])

  return { deliveryBasisOptions }
}

export const useDraftApi = ({ withTemplate, sourceOfOpening, initialDraft }: IUseDraftProps) => {
  const [draft, setDraft] = useState<IHarvestSaleDraft | undefined>(initialDraft)
  const [draftService] = useState(new DraftService())

  const doFetchDraft = useCallback(
    (cultureId: string) => {
      if (withTemplate) {
        return
      }

      if (!cultureId) {
        return
      }

      const fetchDraft = async () => {
        const data = await draftService.getDraft(cultureId)
        setDraft(data)
      }

      fetchDraft()
    },
    [withTemplate, draftService],
  )

  const saveDraft = async (formValues: IRequestHarvestSaleByTemplateFormValues) => {
    if (withTemplate) {
      return
    }

    const valuesToSave = getCreatingDraftHarvestRequestBody(transformFormValuesToRequestPayload(formValues, sourceOfOpening))

    if (isEmpty(draft)) {
      draftService.createDraft(valuesToSave)
    } else {
      draftService.updateDraft(valuesToSave)
    }
  }

  return {
    draft,
    fetchDraft: doFetchDraft,
    saveDraft,
  }
}
