import type { ICheckboxButtonsProps } from '@frontend/pole-ui/lib/components/CheckboxButtons'
import type { IOption } from '@/components/ui/interfaces'
import type { IOptionsIndicator } from '@/logic/harvestSale/interfaces'

export const transformIndicatorOptionsToCheckboxOptions = (options: IOptionsIndicator[]): IOption[] => {
  return options.map(option => {
    const { name: optionName, value: optionValue, additionalParams } = option

    return {
      name: `${optionName}${additionalParams?.unit || ''}`,
      value: optionValue,
    }
  })
}

export const transformIndicatorOptionsToCheckboxButtonsOptions = (options: IOptionsIndicator[]): ICheckboxButtonsProps['options'] =>
  options.map(option => {
    const { name: optionName, value: optionValue, additionalParams } = option

    return {
      text: `${optionName}${additionalParams?.unit || ''}`,
      value: optionValue,
    }
  })
