import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { LotBanner } from '@/components/composed/harvestSaleLots'
import { isUserAgrarian, isUserBuyer, isUserContentManager, isUserSupplier } from '@/logic/auth/reducer'
import type { RootState } from '@/redux/interfaces'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import Template from '../Template'
import type { ITemplatesProps } from './interfaces'
import './styles.scss'

const mapState = (state: RootState) => ({
  isAgrarian: isUserAgrarian()(state),
  isSupplier: isUserSupplier()(state),
  isBuyer: isUserBuyer()(state),
  isContentManager: isUserContentManager()(state),
})

const Templates: React.FC<ITemplatesProps> = ({ templates, onBannerButtonClick, openRequestingHarvestSaleModal }) => {
  const { isAgrarian, isSupplier, isBuyer, isContentManager } = useSelector(mapState)
  const withoutBanner = isSupplier || isBuyer || isContentManager

  const { isMobile, isSmall } = useDeviceType()

  return (
    <div className="templates">
      {templates.map((template, index) => (
        <Fragment key={template.id}>
          <Template
            template={template}
            openRequestingHarvestSaleModal={openRequestingHarvestSaleModal}
            isAgrarian={isAgrarian}
            isMobileDevice={isMobile}
            isSmallMobileDevice={Boolean(isSmall)}
          />
          {!withoutBanner && index === Math.min(templates.length - 1, 1) && (
            <LotBanner className="space-holder16-from-sm space-holder2-sm" onButtonClick={onBannerButtonClick} />
          )}
        </Fragment>
      ))}
    </div>
  )
}

export default Templates
