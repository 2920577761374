import compact from 'lodash/compact'
import type { ITemplate } from '@/types/HarvestSaleLots'

export const getElevatorNameForTemplate = (template: ITemplate) => {
  const { elevator, fcaRegion } = template

  if (elevator) {
    const { name: elevatorName, location } = elevator
    const { address, region } = location || {}
    const { name: regionName } = region

    return compact([elevatorName, regionName, address]).join(', ')
  }

  if (fcaRegion) return fcaRegion.name

  return ''
}
