import React, { useContext } from 'react'
import { Button, LotPrices, LotQualities } from '@/components/ui'
import { pushHarvestGtmEvent } from '@/logic/metrika/harvest/gtmEvents'
import { formatDate, formatLocalizedString } from '@/utils/formatUtils'
import { useActionWithAuthorization } from '@/utils/hooks/useActionWithAuthorization'
import { getElevatorNameForTemplate } from '@/utils/outputs/lot'
import { HarvestSaleLotsContext, storePlaceholder } from '../../constants'
import type { ITemplateProps } from './interfaces'
import { messages } from './messages'
import './styles.scss'

const Template: React.FC<ITemplateProps> = props => {
  const { template, openRequestingHarvestSaleModal, isAgrarian, isMobileDevice, isSmallMobileDevice } = props
  const { culture, buyer, qualityIndicators } = template
  const { name: harvestName, id: cultureId } = culture
  const { name: buyerName } = buyer

  const { formValues } = useContext(HarvestSaleLotsContext)

  const { makeAuthorizedAction, isAuthenticated } = useActionWithAuthorization(() => {
    pushHarvestGtmEvent({
      items: [template],
      pagePart: 'content',
      blockName: 'blockListingOutputs',
      eventCategory: 'ecommerce',
      eventAction: 'click',
      eventLabel: 'buttonSubmitApplication',
      eventStatus: 'success',
      ecommerceType: 'add',
      eventEcommerce: 'addToCart',
      eventNonInteraction: '0',
      itemContext: 'listingLotsOutputs',
      actionField: {
        list: 'listingLotsOutputs',
      },
    })

    openRequestingHarvestSaleModal({ template, initialCultureId: cultureId })
  }, isAgrarian)

  const isStoreChosen = Boolean(formValues?.store) && formValues?.store !== storePlaceholder

  const elevatorDescription = getElevatorNameForTemplate(template)

  return (
    <div className="template">
      <div className="template__header">
        <div className="template-harvest-info">
          {isMobileDevice && (
            <div className="row">
              {template.validTo && (
                <div className="template__validTo">
                  <span className="validToDate">
                    {formatLocalizedString(messages.validTo, { date: formatDate(template.validTo, 'dd.MM.YY') })}
                  </span>
                </div>
              )}
              <div className="template__validTo">
                <span className="template-price-details">{messages.info}</span>
              </div>
            </div>
          )}
          <div className="template__harvestName">{harvestName}</div>

          {qualityIndicators && <LotQualities className="template__qualityParams text_small" quality={qualityIndicators} />}
        </div>
        <div>
          {!isMobileDevice && (
            <div className="row">
              <div className="template__validTo">
                {template.validTo && (
                  <span className="validToDate">
                    {formatLocalizedString(messages.validTo, { date: formatDate(template.validTo, 'dd.MM.YY') })}
                  </span>
                )}
                <span className="template-price-details">{messages.info}</span>
              </div>
            </div>
          )}
          <LotPrices
            className={`space-holder16 row-justify_between ${isMobileDevice ? 'row-wrap' : 'row_nowrap'}`}
            isAgrarian={isAgrarian}
            isMobileDevice={isMobileDevice}
            isStoreChosen={isStoreChosen}
            template={template}
          />
        </div>
      </div>
      <div className="template__footer space-holder-top16">
        <div className="template-buyer-info text_small-from-sm">
          <div>{buyerName}</div>
          {elevatorDescription && <div className="space-holder-top2 color_pale-black">{elevatorDescription}</div>}
        </div>
        <div className="template-actions">
          <Button
            modifiers={['green']}
            className="template__button space-holder-left16 text_small-from-sm"
            onClick={makeAuthorizedAction}
            isHidden={Boolean(isAuthenticated) && !isAgrarian}
          >
            {isSmallMobileDevice ? messages.sendAnOfferButton.short : messages.sendAnOfferButton.text}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Template
