import type { FC } from 'react'
import React, { useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import type { IDropdownOption } from '@frontend/pole-ui/lib/components/Dropdown'
import classNames from 'classnames'
import { EAccessControlValue } from '@/components/composed/AccessControlProvider/types'
import { Dropdown } from '@/components/ui/fields'
import { pushGtmEventOnChangeFilterFormClick } from '@/logic/metrika/harvest/helpers'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import { useHarvestSaleLotsAccessControl } from '@/pages/HarvestSaleLots/components/HarvestSaleLotsAccessControlProvider'
import type { RootState } from '@/redux/interfaces'
import { EPriceVariant } from '@/types/HarvestSaleLots'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { HarvestSaleLotsContext } from '../../constants'
import { TopFilterTabletAndMobile } from '../TopFilter/components'
import { SelectedValue, StoreFilter } from './components'
import type { IDropdownFilters } from './interfaces'
import { messages } from './messages'
import './styles.scss'

const mapState = (state: RootState) => ({
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

const DropdownFilters: FC<IDropdownFilters> = ({ className }) => {
  const { userGtmInfo, userRegion } = useSelector(mapState)
  const { isDesktop } = useDeviceType()
  const { resolve } = useHarvestSaleLotsAccessControl()

  const { onChangeFilter } = useContext(HarvestSaleLotsContext)
  const { formatMessage } = useIntl()

  const priceSortOrderOptions: IDropdownOption<EPriceVariant>[] = useMemo(
    () =>
      Object.entries(EPriceVariant).map(([sortOrderKey, sortOrderValue]) => ({
        label: formatMessage({ id: messages[sortOrderKey as keyof typeof messages] }),
        value: sortOrderValue,
      })),
    [formatMessage],
  )

  return (
    <div className={classNames('page-wrapper_mobile page-wrapper_tablet row row-justify_between row_column-md', className)}>
      <div className="harvest-sale-dropdown-filters-grid">
        {!isDesktop && <TopFilterTabletAndMobile />}
        <Dropdown
          name="sortBy"
          options={priceSortOrderOptions}
          modifiers={['white']}
          dropdownModifiers={['black']}
          className="harvest-sale-dropdown-filters__dropdown"
          SelectedValue={SelectedValue}
          onChange={value => {
            onChangeFilter()
            pushGtmEventOnChangeFilterFormClick(userGtmInfo, userRegion.name, 'sortBy', value)
          }}
        />
      </div>
      {resolve('harvestSaleLotsPage.dropdownFilters.storeFilter') === EAccessControlValue.VISIBLE && <StoreFilter />}
    </div>
  )
}

export default DropdownFilters
