import type { PropsWithChildren } from 'react'
import React from 'react'
import type { IDropdownOption, IDropdownSelectedValueProps } from '@frontend/pole-ui/lib/components/Dropdown'
import { formatLocalizedString } from '@/utils/formatUtils'
import { useDeviceType } from '@/utils/hooks/useDeviceType'
import { messages } from './messages'

function SelectedValue<GValue = string>({ item }: PropsWithChildren<IDropdownSelectedValueProps<GValue>>) {
  const { isDesktop } = useDeviceType()

  return (
    <span>
      {formatLocalizedString(isDesktop ? messages.selected : messages.selectedMobile, {
        sortOrder: (item as IDropdownOption<GValue>)?.value || '',
      })}
    </span>
  )
}

export default SelectedValue
