import React, { useContext } from 'react'
import { Form } from 'react-final-form'
import { Button } from '@/components/ui'
import { Dropdown, TextInput } from '@/components/ui/fields'
import { transformOptions } from '@/components/ui/fields/helpers/Dropdown'
import { pushBtnBannerHarvestGtmEvent } from '@/logic/metrika/harvest/gtmEvents'
import { useDeliveryBasisOptions } from '@/pages/HarvestSaleLots/components/RequestHarvestSaleByTemplateModal/hooks'
import type { IRequestHarvestSaleByTemplateFormValues } from '@/pages/HarvestSaleLots/components/RequestHarvestSaleByTemplateModal/interfaces'
import { HarvestSaleLotsContext, harvestSaleVolumeLimits } from '@/pages/HarvestSaleLots/constants'
import { EOrderType } from '@/types/HarvestSaleLots'
import { useActionWithAuthorization } from '@/utils/hooks/useActionWithAuthorization'
import { useCurrentRegion } from '@/utils/outputs/currentRegion'
import { useHarvestSaleLotsPageParams } from '@/utils/outputs/useHarvestSaleLotsPageParams'
import type { IDemoHarvestSaleRequestProps } from './interfaces'
import { messages } from './messages'
import './styles.scss'

const DemoHarvestSaleRequest: React.FC<IDemoHarvestSaleRequestProps> = ({ isAgrarian }) => {
  const { region } = useCurrentRegion()
  const { openRequestingHarvestSaleModal, cultures } = useContext(HarvestSaleLotsContext)
  const { cultureId } = useHarvestSaleLotsPageParams()
  const { deliveryBasisOptions } = useDeliveryBasisOptions()

  const cultureOptions = transformOptions({ options: cultures, valueKey: 'id' })

  const { makeAuthorizedAction } = useActionWithAuthorization<(values: Partial<IRequestHarvestSaleByTemplateFormValues>) => void>(
    values => {
      openRequestingHarvestSaleModal?.({
        currentCultureId: values.cultureId,
        customInitialFormValues: {
          ...values,
          quality: {},
          orderType: EOrderType.multiple,
          vatUsed: false,
          regionCode: region?.code,
        },
        isOwnOffer: true,
      })

      pushBtnBannerHarvestGtmEvent({
        items: [{ name: 'applicationForm', category: cultureId }],
        pagePart: 'content',
        blockName: 'blockListingOutputs',
        eventCategory: 'ecommerce',
        eventAction: 'click',
        eventLabel: 'buttonCreateApplication',
        eventStatus: 'success',
        eventEcommerce: 'addToCart',
        ecommerceType: 'add',
        eventNonInteraction: '0',
        trueEventContext: 'list:listingLotsOutputs',
        actionField: {
          list: 'listingLotsOutputs',
        },
      })
    },
    isAgrarian,
  )

  return (
    <div className="demo-harvest-sale-request">
      <Form<Partial<IRequestHarvestSaleByTemplateFormValues>>
        onSubmit={values => makeAuthorizedAction(values)}
        initialValues={{ cultureId }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Dropdown
              name="cultureId"
              options={cultureOptions}
              placeholder={messages.culture}
              className="demo-harvest-sale-request__field"
            />
            <Dropdown
              name="deliveryOptions.deliveryBasisId"
              options={deliveryBasisOptions}
              placeholder={messages.deliveryBasis}
              className="demo-harvest-sale-request__field"
            />
            <TextInput
              name="volume"
              label={messages.volume.title}
              type="price"
              isRequiredWithoutSign
              minValue={harvestSaleVolumeLimits.min}
              maxValue={harvestSaleVolumeLimits.max}
              className="demo-harvest-sale-request__field demo-harvest-sale-request__volume"
            >
              <span>{messages.volume.label}</span>
            </TextInput>
            <Button type="submit" modifiers={['green', 'full-width-always']} onClick={handleSubmit} className="space-holder-top24-sm">
              {messages.createRequest}
            </Button>
          </form>
        )}
      </Form>
    </div>
  )
}

export default DemoHarvestSaleRequest
