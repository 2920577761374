import { hasUserPermissions, visibleForPermissionsRule } from '@/components/composed/AccessControlProvider/helpers'
import type { TAccessControlRules } from '@/components/composed/AccessControlProvider/types'
import { EAccessControlValue } from '@/components/composed/AccessControlProvider/types'
import { EUserPermission } from '@/types'
import type { THarvestSaleLotsAccessControlContext } from './types'

const isCreateRequestFormShown = ({ auth }: THarvestSaleLotsAccessControlContext) =>
  !auth.isAuthenticated || hasUserPermissions([EUserPermission.AGRARIAN], auth.user)

export const HARVEST_SALE_LOTS_ACCESS_CONTROL_LIST = {
  harvestSaleLotsPage: {
    dropdownFilters: {
      storeFilter: visibleForPermissionsRule([EUserPermission.AGRARIAN]),
    },
    noTemplatesNotice: {
      noLotsPlaceholder: context => (!isCreateRequestFormShown(context) ? EAccessControlValue.VISIBLE : EAccessControlValue.HIDDEN),
      createRequestForm: context => (isCreateRequestFormShown(context) ? EAccessControlValue.VISIBLE : EAccessControlValue.HIDDEN),
    },
  },
} satisfies Readonly<TAccessControlRules<THarvestSaleLotsAccessControlContext>>
